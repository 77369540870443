<template>
  <div class="container">
    <div class="login b_1_51535e brs_10">
      <div class=" b_1_51535e w_88 m_30_auto brs_10 p_tb_3"></div>
      <el-form ref="form" class="p_lr_60" :model="form" @keyup.enter.native="onSubmit('form')">
        <el-form-item prop="mobile">
          <el-input class="bg_none" v-model="form.mobile" maxlength="11" placeholder="账号"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="form.password" minlength="6" maxlength="20" type="password" placeholder="密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="bg_3f5e8d w-100 dy_block b_none" type="primary" @click="onSubmit('form')" >登 录</el-button>
        </el-form-item>

      </el-form>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      let isMobile = (rule, value, callback) => {
        //实时把非数字的输入过滤掉
        this.form.mobile = this.filterNumber(value);
        //验证
        if (!this.phoneReg.test(value) && value.length == 11) {

          return callback(new Error('请输入绑定的手机号'))
        } else {
          callback()
        }
      };
      let isPassword = (rule, value, callback) => {
        this.form.password = this.filterPwd(value);
        if (!this.passwordReg.test(value) && value.length == 20) {
          return callback(new Error(this.passwordTips))
        } else {
          callback()
        }
      };
      return {
        //验证图片
        form: {
          mobile: '',
          password: '',
        },
        remember: false,
        rules: {
          mobile: [{
              required: true,
              message: '请输入绑定的手机号',
              trigger: 'blur'
            },
            {
              min: 11,
              max: 11,
              message: '请输入绑定的手机号',
              trigger: 'blur'
            },
            {
              validator: isMobile
            }
          ],
          password: [{
            min: 6,
            max: 20,
            required: true,
            message: '请输入登陆密码',
            trigger: 'blur'
          }, {
            validator: isPassword
          }],

        }
      }
    },
    methods: {
      onSubmit() {
        let para={
          admin:this.form
        }
        this.onLoad(true)
        this.loginProject(para, function(res) {
          this.onLoad(false)
          if (res.data.code == 200) {
            localStorage.setItem(this.sy,JSON.stringify(res.data.data));
            this.$message({
              message: res.data.message,
              type: 'success'
            });
            this.$router.push('/processed');
          } else {
            this.msgError(res.data.message);
          }

        })
      },
    },
    created() {
      /* var lett = this;
      document.onkeydown = function(e) {
        var key = window.event.keyCode;
        if (key == 13) {
          lett.onSubmit('form');
        }
      } */
    }
  }
</script>

<style lang="less">
  .container {
    .login {
      width: 580px;
      padding: 20px;
      background: rgba(0, 0, 0, 0.3);
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -80%);

      .el-form-item__label {
        text-align-last: justify;
      }

      input {
        color: #fff;
      }

      .el-input-group__append {
        background: none !important;
      }
    }
  }
</style>
